import Masonry from 'masonry-layout'

import 'svgxuse'

import './replace-strings'

$ = jQuery.noConflict()

function mainProductLogic() {
  const productAvailable = wpGlobals.buyproduct

  let cart = JSON.parse(localStorage.getItem('cart')) || []
  const cartDOM = document.querySelector('.cart')
  const addToCartButtonsDOM = [
    ...document.querySelectorAll('[data-action="ADD_TO_CART"]')
  ]

  if (cart.length > 0) {
    cart.forEach(cartItem => {
      const product = cartItem
      insertItemToDOM(product)
      countCartTotal()

      handleActionButtons(product)
    })

    addCartButtonMarker()

    showCartBtn()
  }

  addToCartButtonsDOM.forEach(addToCartButtonDOM => {
    addToCartButtonDOM.addEventListener('click', () => {
      const productDOM = addToCartButtonDOM.parentNode
      const product = {
        image: productDOM.querySelector('.product__image').getAttribute('src'),
        name: productDOM.querySelector('.product__name').innerText,
        price: productDOM.querySelector('.product__price').innerText,
        quantity: 1
      }

      const isInCart =
        cart.filter(cartItem => cartItem.name === product.name).length > 0

      if (!isInCart) {
        insertItemToDOM(product)
        cart.push(product)
        saveCart()
        handleActionButtons(product)
      } else {
        shakeButton()
      }

      addCartButtonMarker()
      document.querySelector('.c-cart').classList.add('c-cart--visible')
      showCartBtn()
    })
  })

  function insertItemToDOM(product) {
    cartDOM.insertAdjacentHTML(
      'beforeend',
      `
    <div class="cart__item">
      <div class="cart__item__image-wrap">
        <img class="cart__item__image" src="${product.image}" alt="${
        product.name
      }">
      </div>
      <div class="cart__item__content">
        <h3 class="cart__item__name">${product.name}</h3>
        <h3 class="cart__item__price"><b>${wpI18n.price}:</b> ${
        product.price
      } €</h3>
        <div class="cart__item__amount">
          <h3><b>${wpI18n.amount}:</b></h3>
          <button class="c-btn c-btn--primary c-btn--small${
            product.quantity === 1 ? ' c-btn--danger' : ''
          }" data-action="DECREASE_ITEM">&minus;</button>
          <h3 class="cart__item__quantity">${product.quantity}</h3>
          <button class="c-btn c-btn--primary c-btn--small" data-action="INCREASE_ITEM">&plus;</button>
          <button class="c-btn-remove-item" data-action="REMOVE_ITEM">&times;</button>
        </div>
      </div>
    </div>
  `
    )

    addCartFooter()
  }

  function handleActionButtons(product) {
    const cartItemsDOM = [...cartDOM.querySelectorAll('.cart__item')]

    cartItemsDOM.forEach(cartItemDOM => {
      if (
        cartItemDOM.querySelector('.cart__item__name').innerText ===
        product.name
      ) {
        cartItemDOM
          .querySelector('[data-action="INCREASE_ITEM"]')
          .addEventListener('click', () => increaseItem(product, cartItemDOM))
        cartItemDOM
          .querySelector('[data-action="DECREASE_ITEM"]')
          .addEventListener('click', () => decreaseItem(product, cartItemDOM))
        cartItemDOM
          .querySelector('[data-action="REMOVE_ITEM"]')
          .addEventListener('click', () => removeItem(product, cartItemDOM))
      }
    })
  }

  function increaseItem(product, cartItemDOM) {
    cart.forEach(cartItem => {
      if (cartItem.name === product.name) {
        cartItemDOM.querySelector(
          '.cart__item__quantity'
        ).innerText = ++cartItem.quantity
        cartItemDOM
          .querySelector('[data-action="DECREASE_ITEM"]')
          .classList.remove('c-btn--danger')
        saveCart()
      }
    })
  }

  function decreaseItem(product, cartItemDOM, addToCartButtonDOM) {
    cart.forEach(cartItem => {
      if (cartItem.name === product.name) {
        if (cartItem.quantity > 1) {
          cartItemDOM.querySelector(
            '.cart__item__quantity'
          ).innerText = --cartItem.quantity
          saveCart()
        } else {
          removeItem(product, cartItemDOM, addToCartButtonDOM)
        }

        if (cartItem.quantity === 1) {
          cartItemDOM
            .querySelector('[data-action="DECREASE_ITEM"]')
            .classList.add('c-btn--danger')
        }
      }
    })
  }

  function removeItem(product, cartItemDOM) {
    cartItemDOM.classList.add('cart__item--removed')
    setTimeout(() => cartItemDOM.remove(), 250)
    cart = cart.filter(cartItem => cartItem.name !== product.name)
    saveCart()

    if (cart.length < 1) {
      document.querySelector('.cart-footer').remove()

      removeCartButtonMarker()
      hideCartBtn()
    }
  }

  function addCartFooter() {
    if (document.querySelector('.cart-footer') === null) {
      cartDOM.insertAdjacentHTML(
        'afterend',
        `
      <div class="cart-footer">
        <button class="c-btn c-btn--clear-cart" data-action="CLEAR_CART">${wpI18n.remove_all}</button>
        <div class="c-cart-total" data-action="TOTAL_SUM"></div>
      </div>
    `
      )

      document
        .querySelector('[data-action="CLEAR_CART"]')
        .addEventListener('click', () => clearCart())
      showCartBtn()
    }
  }

  function clearCart() {
    cartDOM.querySelectorAll('.cart__item').forEach(cartItemDOM => {
      cartItemDOM.classList.add('cart__item--removed')
      setTimeout(() => cartItemDOM.remove(), 250)
    })

    cart = []
    localStorage.removeItem('cart')
    document.querySelector('.cart-footer').remove()

    removeCartButtonMarker()
    hideCartBtn()
  }

  function countCartTotal() {
    let cartTotal = 0
    cart.forEach(cartItem => (cartTotal += cartItem.quantity * cartItem.price))
    document.querySelector(
      '[data-action="TOTAL_SUM"]'
    ).innerText = `${wpI18n.total_lg}: ${cartTotal} €`
  }

  function saveCart() {
    localStorage.setItem('cart', JSON.stringify(cart))
    countCartTotal()
  }

  function updateProductName() {
    const dropdown = document.querySelector('.select-parameter')
    const addToCartButton = document.querySelector('.c-btn--add-to-cart')

    dropdown.addEventListener('change', () => {
      const productNAME = document.querySelector(
        '[data-action="ADDED_CONTENT"]'
      )

      const value = dropdown.options[dropdown.selectedIndex].value
      console.log(value)

      productNAME.innerHTML = `(${wpI18n.strength}: ${value})`

      addToCartButton.classList.remove('c-btn--disabled')
    })
  }

  // Allow product to be added to cart only when it's available
  if (productAvailable) {
    updateProductName()
  }

  function shakeButton() {
    const button = document.querySelector('.c-btn--add-to-cart')
    button.classList.add('c-btn--add-to-cart--shake')

    setTimeout(() => button.classList.remove('c-btn--add-to-cart--shake'), 1000)
  }
}

//

// SUBPAGE SHOPPING CART START

//

function subPageLogic() {
  let cart = JSON.parse(localStorage.getItem('cart')) || []
  const cartDOM = document.querySelector('.cart')

  if (cart.length > 0) {
    cart.forEach(cartItem => {
      const product = cartItem
      insertItemToDOM(product)
      countCartTotal()

      handleActionButtons(product)
    })

    addCartButtonMarker()

    if (
      !document.body.className.match('page-template-template-shopping_cart')
    ) {
      openCart()
      closeCart()
    }

    showCartBtn()
  }

  function insertItemToDOM(product) {
    cartDOM.insertAdjacentHTML(
      'beforeend',
      `
    <div class="cart__item">
      <div class="cart__item__image-wrap">
        <img class="cart__item__image" src="${product.image}" alt="${
        product.name
      }">
      </div>
      <div class="cart__item__content">
        <h3 class="cart__item__name">${product.name}</h3>
        <h3 class="cart__item__price"><b>${wpI18n.price}:</b> ${
        product.price
      } €</h3>
        <div class="cart__item__amount">
          <h3><b>${wpI18n.amount}:</b></h3>
          <button class="c-btn c-btn--primary c-btn--small${
            product.quantity === 1 ? ' c-btn--danger' : ''
          }" data-action="DECREASE_ITEM">&minus;</button>
          <h3 class="cart__item__quantity">${product.quantity}</h3>
          <button class="c-btn c-btn--primary c-btn--small" data-action="INCREASE_ITEM">&plus;</button>
          <button class="c-btn-remove-item" data-action="REMOVE_ITEM">&times;</button>
        </div>
      </div>
    </div>
  `
    )

    addCartFooter()
  }

  function handleActionButtons(product) {
    const cartItemsDOM = [...cartDOM.querySelectorAll('.cart__item')]
    cartItemsDOM.forEach(cartItemDOM => {
      if (
        cartItemDOM.querySelector('.cart__item__name').innerText ===
        product.name
      ) {
        cartItemDOM
          .querySelector('[data-action="INCREASE_ITEM"]')
          .addEventListener('click', () => increaseItem(product, cartItemDOM))
        cartItemDOM
          .querySelector('[data-action="DECREASE_ITEM"]')
          .addEventListener('click', () => decreaseItem(product, cartItemDOM))
        cartItemDOM
          .querySelector('[data-action="REMOVE_ITEM"]')
          .addEventListener('click', () => removeItem(product, cartItemDOM))
      }
    })
  }

  function increaseItem(product, cartItemDOM) {
    cart.forEach(cartItem => {
      if (cartItem.name === product.name) {
        cartItemDOM.querySelector(
          '.cart__item__quantity'
        ).innerText = ++cartItem.quantity
        cartItemDOM
          .querySelector('[data-action="DECREASE_ITEM"]')
          .classList.remove('c-btn--danger')
        saveCart()
      }
    })
  }

  function decreaseItem(product, cartItemDOM) {
    cart.forEach(cartItem => {
      if (cartItem.name === product.name) {
        if (cartItem.quantity > 1) {
          cartItemDOM.querySelector(
            '.cart__item__quantity'
          ).innerText = --cartItem.quantity
          saveCart()
        } else {
          removeItem(product, cartItemDOM)
        }

        if (cartItem.quantity === 1) {
          cartItemDOM
            .querySelector('[data-action="DECREASE_ITEM"]')
            .classList.add('c-btn--danger')
        }
      }
    })
  }

  function removeItem(product, cartItemDOM) {
    cartItemDOM.classList.add('cart__item--removed')
    setTimeout(() => cartItemDOM.remove(), 250)
    cart = cart.filter(cartItem => cartItem.name !== product.name)
    saveCart()

    if (cart.length < 1) {
      document.querySelector('.cart-footer').remove()

      removeCartButtonMarker()
      hideCartBtn()
    }
  }

  function addCartFooter() {
    if (document.querySelector('.cart-footer') === null) {
      cartDOM.insertAdjacentHTML(
        'afterend',
        `
      <div class="cart-footer">
        <button class="c-btn c-btn--clear-cart" data-action="CLEAR_CART">${wpI18n.remove_all}</button>
        <div class="c-cart-total" data-action="TOTAL_SUM"></div>
      </div>
    `
      )

      document
        .querySelector('[data-action="CLEAR_CART"]')
        .addEventListener('click', () => clearCart())
    }
  }

  function clearCart() {
    cartDOM.querySelectorAll('.cart__item').forEach(cartItemDOM => {
      cartItemDOM.classList.add('cart__item--removed')
      setTimeout(() => cartItemDOM.remove(), 250)
    })

    cart = []
    localStorage.removeItem('cart')
    document.querySelector('.cart-footer').remove()
    removeCartButtonMarker()

    hideCartBtn()
  }

  function countCartTotal() {
    let cartTotal = 0
    cart.forEach(cartItem => (cartTotal += cartItem.quantity * cartItem.price))
    document.querySelector(
      '[data-action="TOTAL_SUM"]'
    ).innerText = `${wpI18n.total_lg}: ${cartTotal} €`
  }

  function saveCart() {
    localStorage.setItem('cart', JSON.stringify(cart))
    countCartTotal()
  }

  function checkout() {
    let orderContent = `
  `
    cart.forEach((cartItem, index) => {
      ++index
      orderContent += `
        ${index} | ${cartItem.name} | ${wpI18n.price}: ${cartItem.price} € | ${wpI18n.amount}: ${cartItem.quantity}
    `
    })
    orderContent += `
      ${document.querySelector('[data-action="TOTAL_SUM"]').innerText}
  `

    document
      .querySelector('.order-summary textarea')
      .insertAdjacentHTML('beforeend', orderContent)
    console.log(orderContent)
  }

  function insertDataToForm() {
    document
      .querySelector('[data-action="DATA_TO_FORM"]')
      .addEventListener('click', () => checkout())
  }

  if (document.body.className.match('page-template-template-shopping_cart')) {
    insertDataToForm()

    $(document).on('gform_confirmation_loaded', (e, form_id) => {
      clearCart()
      hideShoppingContent()
    })
  }
}

function openCart() {
  const cartIcon = document.querySelector('.js-open-cart')
  const cart = document.querySelector('.c-cart')

  cartIcon.addEventListener('click', () => {
    cart.classList.add('c-cart--visible')
  })
}

function closeCart() {
  const cartClose = document.querySelector('.js-close-cart')
  const cart = document.querySelector('.c-cart')

  cartClose.addEventListener('click', () => {
    cart.classList.remove('c-cart--visible')
  })
}

function addCalendarClasses() {
  const steps = $('.step')
  for (let i = 0; i < steps.length; i += 5) {
    if (i < 1) {
      steps.slice(i, i + 5).wrapAll('<div class="steps-main-wrap"></div>')
    }
  }
}

function addClassTocBtns() {
  $('.final div:last-child').addClass('calendar-footer')
}

function addDisabledFinalStep() {
  const finalStep = document.querySelector('.step.final')
  finalStep.classList.add('disabled')
}

function addbuttonHTML() {
  const btn = document.querySelector('.ea-btn.ea-submit.btn.btn-primary')

  // btn.onclick = function ga() {
  //   return gtag_report_conversion('https://opti.ee/registreeru-silmakontrolli/')
  // }
}

function removeDisabledFinalStep() {
  const finalStep = document.querySelector('.step.final')
  const calendar = document.querySelector('.calendar')

  calendar.addEventListener('click', () => {
    finalStep.classList.remove('disabled')
  })
}

function addCartButtonMarker() {
  const cartButton = document.querySelector('.c-cart-button')
  cartButton.classList.add('cart-has-items')
}

function removeCartButtonMarker() {
  const cartButton = document.querySelector('.c-cart-button')
  cartButton.classList.remove('cart-has-items')
}

function addFacetRefreshAnimation() {
  $(document).on('facetwp-refresh', () => {
    if (FWP.loaded) {
      $('.facetwp-template').prepend('<div class="facets-loading"></div>')
    }
  })
}

function showCartBtn() {
  if (!document.body.className.match('page-template-template-shopping_cart')) {
    const cartButton = document.querySelector('.c-btn--complete-order')
    const cartNotification = document.querySelector('.c-cart-inner-info')
    cartButton.classList.add('visible')
    cartNotification.classList.add('hidden')
  }
}

function hideCartBtn() {
  if (!document.body.className.match('page-template-template-shopping_cart')) {
    const cartButton = document.querySelector('.c-btn--complete-order')
    const cartNotification = document.querySelector('.c-cart-inner-info')
    cartButton.classList.remove('visible')
    cartNotification.classList.remove('hidden')
  }
}

function initiateBestsellersSlider() {
  $('.js-bestsellers-slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    speed: 500,
    prevArrow: $('.c-slider-prev'),
    nextArrow: $('.c-slider-next'),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })
}

function initiateBrandsSlider() {
  $('.js-brands-slider').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    speed: 500,
    prevArrow: $('.c-brands-slider-prev'),
    nextArrow: $('.c-brands-slider-next'),
    responsive: [
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })
}

function initiateHeroSlider() {
  $('.js-hero-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    dots: true,
    arrows: false
  })
}

function toggleSubmitForm() {
  const form = $('.c-order-form-wrap')
  const submitBtn = $('.c-btn--complete-order')
  const cartWrap = $('.c-cart-wrap')

  form.hide()

  submitBtn.click(() => {
    form.slideToggle()
    cartWrap.addClass('locked')
  })
}

function initiateGrid() {
  const grids = [...document.querySelectorAll('.js-grid')]

  grids.forEach(element => {
    const msnry = new Masonry(element, {
      itemSelector: '.grid-item'
    })
    msnry.layout()
  })
}

function activateSmoothScroll() {
  $('.js-scroll').click(function(e) {
    e.preventDefault()
    const scrollDest = $(this).attr('href')

    $('html, body').animate({
      scrollTop: $(scrollDest).offset().top - 20
    })
  })
}

function hideShoppingContent() {
  $('.o-inner-wrap--shopping').addClass('hidden')
}

function openModal() {
  $('.js-open-popup').magnificPopup({
    type: 'inline',
    closeMarkup: '<div title="%title%" class="mfp-close modal-close"></div>',
    midClick: true,
    mainClass: 'mfp-fade'
  })
}

function openMobileMenu() {
  const $hamburger = $('.hamburger')
  $hamburger.on('click', e => {
    $hamburger.toggleClass('is-active')
    $('.js-toggle-menu').toggleClass('visible')
  })
}

openMobileMenu()

openModal()

if (document.body.className.match('page-template-template-register')) {
  // addCalendarClasses()
  addClassTocBtns()
  // addDisabledFinalStep()
  // removeDisabledFinalStep()

  $(document).ready(() => {
    addbuttonHTML()
  })
}

if (document.body.className.match('post-type-archive-location')) {
  addFacetRefreshAnimation()
}

if (document.body.className.match('home')) {
  initiateBestsellersSlider()
  initiateBrandsSlider()
  initiateHeroSlider()
}

if (document.body.className.match('single-product')) {
  mainProductLogic()
}

if (document.body.className.match('post-type-archive-product')) {
  activateSmoothScroll()
}

if (!document.body.className.match('single-product')) {
  subPageLogic()
}

if (!document.body.className.match('page-template-template-shopping_cart')) {
  openCart()
  closeCart()
}

if (document.body.className.match('page-template-template-shopping_cart')) {
  toggleSubmitForm()
}

if (document.body.className.match('page-template-template-doctors')) {
  initiateGrid()
}
