import $ from 'jquery'

function replaceStrings() {
  function runReplaceStrings() {
    const labels = document.querySelectorAll('.ea-label')

    const service = wpI18n.service
    const location = wpI18n.location
    const worker = wpI18n.worker

    labels.forEach((label) => {
      if (label.innerText === 'Teenus') {
        label.innerHTML = service
      } else if (label.innerText === 'Asukoht') {
        label.innerHTML = location
      } else if (label.innerText === 'Töötaja') {
        label.innerHTML = worker
      }
    })
  }

  setTimeout(() => {
    runReplaceStrings()
  }, 300)
}

if ($('body').hasClass('page-template-template-register')) {
  $(document).ready(() => {
    // replaceStrings()

    const calendar = document.querySelector('.calendar')

    calendar.addEventListener('click', replaceStrings)
  })
}
